import ScrollReveal from 'scrollreveal'
import './scrollRevealWatch'

const reveal = $target => {
  if (!$target) return
  ScrollReveal().watch({
    $target,
    onEnter($el) {
      $el.classList.add('js-visible')
    },
  })
}

const animatedBlockBuilder = () => {
  const $block = Array.from(
    document.querySelectorAll(
      'section[class^="b-"] .animate-in-bottom, section[class^="b-"] .animate-in-left, section[class^="b-"] .animate-in-right'
    )
  )
  $block.forEach($elem => {
    reveal($elem)
  })
}
animatedBlockBuilder()
