import ScrollReveal from 'scrollreveal'

const noon = () => {}
// https://github.com/scrollreveal/scrollreveal/issues/337
const scrollRevealWatch = () => {
  ScrollReveal().watch = function({
    $target,
    onEnter = noon,
    onExit = noon,
    opts = {},
  }) {
    if (typeof onEnter === 'function' && typeof onExit === 'function') {
      const defaults = {
        delay: 100,
        distance: 0,
        duration: 0,
        scale: 1,
        opacity: null,
        rotate: { x: 0, y: 0, z: 0 },
        reset: false,
        beforeReset: onExit,
        beforeReveal: onEnter,
        viewOffset: {
          top: 80,
          right: 0,
          bottom: 0,
          left: 0,
        },
        // viewFactor: 0.2,
      }

      this.reveal($target, Object.assign(defaults, opts))
    } else {
      throw new Error('Watch received invalid arguments.')
    }
  }
}
scrollRevealWatch()
