const debug = true

const activeGroupsToArray = groups => {
  groups = groups.replace(/^,|,$/g, '')
  return groups.split(',').map(Number)
}

let previousGroups = window.OnetrustActiveGroups || []

export const updatePreviousGroups = newGroups => {
  previousGroups = newGroups
}

export const checkUserHasAcceptedCookies = (requiredGroups = [1, 2, 3, 4]) => {
  const activeGroups = activeGroupsToArray(window.OnetrustActiveGroups || '')
  if (debug) console.log('Active Groups:', activeGroups)
  let allGroupsAccepted = true
  requiredGroups.forEach(group => {
    if (!activeGroups.includes(group)) {
      if (debug) console.log('Active Group not accepted:', group)
      allGroupsAccepted = false
    }
  })
  return allGroupsAccepted
}

export let cookiesAccepted = checkUserHasAcceptedCookies()

export const removeCookieConsentIframeBlocker = () => {
  const blockers = [
    ...document.querySelectorAll('.cookie-consent-iframe-blocker'),
  ]
  blockers.forEach(blocker => {
    blocker.classList.add('-hide')
  })
}

export const acceptAllCookies = callback => {
  const btns = [...document.querySelectorAll('.js-accept-all-cookies')]
  btns.forEach(btn => {
    const newBtn = btn.cloneNode(true)
    btn.replaceWith(newBtn)
    newBtn.addEventListener('click', () => {
      console.log('acceptAllCookies clicked')
      OneTrust.AllowAll()
      cookiesAccepted = true
      removeCookieConsentIframeBlocker()
      if (callback) callback()
    })
  })
}

export const loadIframes = () => {
  const blockedIframeWrappers = document.querySelectorAll(
    '.cookie-consent-iframe-blocker__wrapper'
  )
  blockedIframeWrappers.forEach(wrapper => {
    const iframe = wrapper.querySelector('iframe')
    const src = iframe.getAttribute('data-src')
    iframe.setAttribute('src', src)
    wrapper
      .querySelector('.cookie-consent-iframe-blocker')
      .classList.add('-hide')
  })
}

export const hideIframes = () => {
  const blockedIframeWrappers = document.querySelectorAll(
    '.cookie-consent-iframe-blocker__wrapper'
  )
  blockedIframeWrappers.forEach(wrapper => {
    const iframe = wrapper.querySelector('iframe')
    iframe.setAttribute('src', '')
    wrapper
      .querySelector('.cookie-consent-iframe-blocker')
      .classList.remove('-hide')
  })
}

export const cookieConsentIframeBlocking = () => {
  const userHasAcceptedCookies = checkUserHasAcceptedCookies()
  if (userHasAcceptedCookies) {
    loadIframes()
  } else {
    acceptAllCookies(() => {
      const updatedUserHasAcceptedCookies = checkUserHasAcceptedCookies()
      if (updatedUserHasAcceptedCookies) {
        loadIframes()
      }
    })
  }
}

export const translateCookieConsentMessages = () => {
  const consentMessages = document.querySelectorAll(
    '.cookie-consent-iframe-blocker__message'
  )
  if (!consentMessages) return
  const translatedMessageContent =
    document
      .querySelector('meta[name="iframe-blocking-message"]')
      .getAttribute('content') ||
    'Please accept cookies in order to view this content'
  const translatedBtnContent =
    document
      .querySelector('meta[name="iframe-blocking-button"]')
      .getAttribute('content') || 'Accept Cookies'

  consentMessages.forEach(el => {
    const pTag = el.querySelector('p')
    if (pTag) {
      pTag.textContent = translatedMessageContent
    }
    const buttonTag = el.querySelector('button')
    if (buttonTag) {
      buttonTag.textContent = translatedBtnContent
    }
  })
}

export const fixParentWrapperStyles = () => {
  const blockerWrappers = document.querySelectorAll(
    '.cookie-consent-iframe-blocker__wrapper'
  )

  blockerWrappers.forEach(wrapper => {
    const parent = wrapper.parentElement
    if (
      parent &&
      parent.getAttribute('style') ===
        'padding: 56.25% 0 0 0; position: relative;'
    ) {
      parent.removeAttribute('style')
      wrapper.setAttribute('style', 'padding: 56.25% 0 0 0; max-width: none;')
    }
  })
}

setInterval(() => {
  const currentGroups = window.OnetrustActiveGroups
  if (JSON.stringify(currentGroups) !== JSON.stringify(previousGroups)) {
    updatePreviousGroups(currentGroups)
    if (checkUserHasAcceptedCookies()) {
      loadIframes()
    } else {
      hideIframes()
    }
  }
}, 1000)
